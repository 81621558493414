import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BrowseNFTs from "../components/BrowseNFTs";
import Burger from "../components/Burger";
import SideNav, { Toggle, Nav, NavItem, NavText } from '@trendmicro/react-sidenav';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import CloudIcon from '@material-ui/icons/Cloud';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BuildIcon from '@material-ui/icons/Build';

const useStyleSideNav = makeStyles({
    sideNav: {
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        minWidth: "0px",
        width: "0px",
        background: "#000",
        transition: "min-width .2s",
        zIndex: "1"
    },
    sideNavExpanded: {
        minWidth: "190px",
        transition: "min-width .2s"
    },
    sidenavNav: {
        float: "left",
        padding: "0",
        margin: "0",
        width: "100%"
    },
    subNavItem: {
        position: "relative",
        display: "none",
        lineHeight: "50px",
        height: "50px"
    },
    subNavItemExpanded: {
        padding: "0 14px 0 40px",
        display: "block"
    },
    navItem: {
        position: "relative",
        lineHeight: "50px",
        paddingLeft: "20px",
        height: "50px",
        opacity: "0",
        transition: "opacity .2s linear"
    },
    navItemExpanded: {
        opacity: "1",
        transition: "opacity .2s linear"
    },
    selected: {
        backgroundColor: "#000"
    },
    toggle: {
        position: "relative",
        float: "left",
        width: "50px",
        height: "50px",
        padding: "0",
        margin: "0",
        backgroundColor: "transparent",
        border: "0",
        borderRadius: "0",
        cursor: "pointer"
    },
    chipItem: {
        fontSize: '1rem',
        color: 'rgba(0, 0, 0, 0.7)',
        cursor: 'pointer'
    },
    subChipItem: {
        fontSize: '0.8rem',
        color: 'rgba(0, 0, 0, 0.7)',
        cursor: 'pointer'
    }
})

const filters = ['client', 'custodian', 'operator']
const filterValues = new Map([
    ['client', ['Teku', 'Prysm', 'Lighthouse', 'Nimbus', 'Trinity', 'Vouch', 'SSV', 'Unknown']],
    ['custodian', ['Smart Contract', 'Bitgo', 'Fireblocks', 'Kraken', 'Unknown']],
    ['operator', ['Blockdaemon', 'Bison Trails', 'ConsenSys', 'Chorus One', 'Stakefish', 'SSV', 'Unknown']]
])
const selectedMap = new Map([
    ['client', false],
    ['custodian', false],
    ['operator', false]
]);
const selectedFilterValues = new Map([
    ['client', ""],
    ['custodain', ""],
    ['operator', ""]
])

const iconMap = new Map([
    ['client', <BuildIcon />],
    ['custodian', <VpnKeyIcon />],
    ['operator', <CloudIcon />]
]);

function useForceUpdate() {
    const [count, setCount] = React.useState(0)
	const increment = () => setCount(prevCount => prevCount + 1)
	return [increment, count]
}

export default function NTFS() {
    const classes = useStyleSideNav();

    const [expanded, setExpanded] = React.useState(false);
    const [forceUpdate] = useForceUpdate()

    const onSelect = (selected) => {
        selectedMap.set(selected, !selectedMap.get(selected));
        forceUpdate()
    }

    const onSelectSub = (selected) => {
        let filter = selected.split("/")
        let prevSelectedFilterValue = selectedFilterValues.get(filter[0])
        let value = ""

        if (prevSelectedFilterValue !== filter[1]) {
            value = filter[1]
        }

        selectedFilterValues.set(filter[0], value)

        forceUpdate()
    }

    const onToggle = (expanded) => {
        setExpanded(expanded);
    }

    const navItemClasses = `${classes.navItem} ${expanded ? classes.navItemExpanded : ""}`
    const sideNavClasses = `${classes.sideNav} ${expanded ? classes.sideNavExpanded : ""}`

    return (
        <Layout>
            <SEO title="Kyne Software NFTs"/>

            <SideNav onToggle={onToggle} className={sideNavClasses}>
                <Toggle className={classes.toggle}>
                    <Burger expanded={expanded} />
                </Toggle>
                <Nav onSelect={onSelect} className={classes.sidenavNav}>
                    {filters.map((filter) => {
                        return [
                            <NavItem eventKey={filter} className={`${navItemClasses} ${ selectedMap.get(filter) ? classes.selected : ""}`}>
                                <NavText title={filter}>
                                    <Chip
                                        label={<span><b>{`${filter[0].toUpperCase()}${filter.slice(1)}`}</b></span>}
                                        className={classes.chipItem}
                                        size="medium"
                                        variant="default"
                                        color="secondary"
                                        icon={iconMap.get(filter)}
                                    />
                                </NavText>
                            </NavItem>,
                            <Nav onSelect={onSelectSub}>
                                {filterValues.get(filter).map((value) => (
                                    <NavItem eventKey={`${filter}/${value}`} className={`${classes.subNavItem} ${ (expanded && selectedMap.get(filter)) ? classes.subNavItemExpanded : "" }`}>
                                        <NavText>
                                            <Chip
                                                icon={ ((selectedFilterValues.get(filter) === value) ? <DoneIcon /> : null) }
                                                label={<span><b>{`${value}`}</b></span>}
                                                className={classes.subChipItem}
                                                size="small"
                                                variant="default"
                                                color="default"
                                            />
                                        </NavText>
                                    </NavItem>
                                ))}
                            </Nav>
                        ]
                    })}
                </Nav>
            </SideNav>

            <BrowseNFTs
                client={selectedFilterValues.get('client')}
                custodian={selectedFilterValues.get('custodian')}
                operator={selectedFilterValues.get('operator')}
            />
        </Layout>
    )
}