import React, { useEffect, useState } from "react";
import { string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Container from "@material-ui/core/Container"
import { graphql, useStaticQuery } from "gatsby";
import { NftCard } from "../components/NftCard"
import { getTokenCount, searchTokenMetadata } from "../services/backend";
import { logger } from "../config/pino";
import { GridList, GridListTile } from "@material-ui/core";
import TablePaginationActions from './TablePaginationActions';
import { useSnackbar } from 'notistack'

const useStyleGrid = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    footer: {
        bottom: "0"
    },
    parent: {
        width: "100%",
        height: "100%"
    },
    topDiv: {
        float: "left",
    },
    bottomDiv: {
        float: "left",
        clear: "left"
    }
})

// Error messages for the snackbar pop up if something goes wrong.
const ERROR_QUERYING_API = "Failed to retrieve NFT metadata."

export default function BrowseNFTs({
    client = "",
    custodian = "",
    operator = ""
}) {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    backendURL
                }
            }
        }
    `)
    
    const gridClasses = useStyleGrid();

    const backend = !!site.siteMetadata ? site.siteMetadata.backendURL : "http://localhost:3000"

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [tokenCount, setTokenCount] = React.useState(0);
    const queries = {
        'client': client,
        'custodian': custodian,
        'operator': operator
    }

    const handleChangePage = (event, newPage) => {
        setIsLoading(true)
        getPageTokenMetadata(newPage, rowsPerPage, queries, backend)
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = event.target.value
        setIsLoading(true)
        getPageTokenMetadata(0, newRowsPerPage, queries, backend)
        setRowsPerPage(parseInt(newRowsPerPage, 10))
        setPage(0);
    }

    // Struct for storing the JSON response from the metadata server.
    const [tokenMetadatas, setTokenMetadatas] = useState([{
        attributes: [{ trait_type: "", value: "" }],
        title: string,
        description: string,
        external_url: string,
        image: string,
        name: string,
        address: string,
        token_id: string,
        EIPXXX: {
            public_key: "",
            validator_index: "",
            document: "",
            withdrawal_key: "",
        },
    }])

    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState("");

    const getPageTokenMetadata = (page, rowsPerPage, queries, backend) => {
        searchTokenMetadata(page, rowsPerPage, queries, backend)
            .then(response => {
                if (!!response && !("error" in response)) {
                    logger.info(`API has returned token metadata: ${JSON.stringify(response)}`)
                    setTokenMetadatas(response)
                    setIsLoading(false)
                    setIsError(false)
                } else {
                    logger.warn(`searchTokenMeta Service returned a falsy value rather than an object:`)
                    logger.warn(response)
                    setIsLoading(false)
                    setIsError(true)
                    setErrorMessage(ERROR_QUERYING_API)
                }
            })
            .catch(err => {
                logger.warn(`Exception thrown retrieving token metadata from server:`)
                logger.warn(err)
                setIsLoading(false)
                setIsError(true)
                setErrorMessage(ERROR_QUERYING_API)
            })
    }

    const { enqueueSnackbar } = useSnackbar();
    
    useEffect(() => {
        const queries = {
            'client': client,
            'custodian': custodian,
            'operator': operator
        }

        getPageTokenMetadata(0, rowsPerPage, queries, backend)

        getTokenCount(queries, backend)
            .then(response => {
                if (!!response && !("error" in response)) {
                    setTokenCount(response.count)
                } else {
                    logger.warn(`getTokenCount Service returned a falsy value rather than an object:`)
                    logger.warn(response)
                    enqueueSnackbar("Failed to retrieve token metadata count", {
                    variant: 'error',
                    autoHideDuration: 3000,
                    });
                }
            })
            .catch(err => {
                logger.warn(`Exception thrown retrieving token metadata count from server:`)
                logger.warn(err)
                enqueueSnackbar("Failed to retrieve token metadata count", {
                variant: 'error',
                autoHideDuration: 3000,
                });
            })

    }, [backend, client, custodian, operator, enqueueSnackbar, rowsPerPage])

    return (
        <Container className={gridClasses.root}>
            <div className={gridClasses.topDiv}>
                <TablePagination
                    className={gridClasses.footer}
                    
                    count={tokenCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[1, 2, 5, 10, { label: 'All', value: -1}]}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage={'NFTs per page:'}
                    ActionsComponent={TablePaginationActions} 
                />
            </div>
            <div className={gridClasses.bottomDiv}>
                <GridList cellHeight="auto" cols={2}>
                    {tokenMetadatas.map((nft) => (
                        <GridListTile cols={1}>
                            <Container maxWidth="xs">
                                <NftCard
                                    isLoading={isLoading}
                                    isError={isError}
                                    nftAddress={nft.address.toString()}
                                    nftTokenId={nft.token_id.toString()}
                                    nftOwner={""}
                                    nftOwnersENS={""}
                                    metadata={nft}
                                    errorMsg={errorMessage}
                                ></NftCard>
                            </Container>
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        </Container>
    )
}