import React from "react";
import styled from 'styled-components';

export default function Burger({ expanded = false }) {
    const Burger = styled.button`
        position: absolute;
        top: 15%;
        left: 15%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 2rem;
        height: 2rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 10;
        span {
            width: 2rem;
            height: 0.25rem;
            background: #888;
            border-radius: 10px;
            position: relative;
            transform-origin: 1px;
            :first-child {
                transform: ${expanded ? 'rotate(45deg)' : 'rotate(0)'};
            }
            :nth-child(2) {
                opacity: ${expanded ? '0' : '1'};
                transform: ${expanded ? 'translateX(20px)' : 'translateX(0)'};
            }
            :nth-child(3) {
                transform: ${expanded ? 'rotate(-45deg)' : 'rotate(0)'};
            }
    }`;

    return (
        <Burger>
            <span />
            <span />
            <span />
        </Burger>
    );
}